import React from 'react'
import '../../App.css'
import './Pages.css'

export default function Education() {
    return (
        <div className='page-container'>
            <h1>
            
                EDUCATION
                
            </h1>
            <div className='column-layout'>
              <div className='left-align'>
                  <h1 style={{ textDecoration: 'underline', marginBottom: '0px'}}>
                  <img src = '../../images/fsu-logo.png' alt = "FSU" className='icon'/>
                    COLLEGE - Florida State University
                  </h1>
                  <div className="dates-ed">
                      <h1>August 2022 - Present(Expected May 2026)</h1>
                  </div>
                  <p style={{marginBottom: '0px'}}><b>Major:</b> BS Computer Science & Computational Biology</p>
                  <p style={{marginBottom: '0px', marginTop: '0px'}}><b>Year:</b> 3</p>
                  <p style={{marginTop: '0px'}}><b>GPA:</b> 3.97</p>
                  <br/>
                  <h2><b>Relevant Coursework:</b></h2>
                  <ul>
                      <li><p>Data Structures and Algorithms(C++)</p></li>
                      <li><p>Software Engineering</p></li>
                      <li><p>Intro to Unix</p></li>
                      <li><p>Statistics for Engineers and Scientists</p></li>
                      <li><p>Advanced Programming in Java</p></li>
                      <li><p>Secure, Distributed, and Paraellel Computing Systems</p></li>
                      <li><p>Calculus I & II</p></li>
                      <li><p>Discrete Math I & II</p></li>
                      <li><p>Biology I & II</p></li>
                      <li><p>Chemistry I</p></li>
                  </ul>
                  <br/>
                  <h2 style={{marginBottom: '0px'}}><b>Awards and Honors:</b></h2>
                  <ul>
                      <li><p><b>Undergraduate Leadership Award</b> - Awarded for outstanding work as ACM President</p></li>
                      <li><p><b>2nd place</b> - Awarded for 2nd best research poster at the 2024 FSU CS Expo.</p></li>
                      <li><p><b>President's list</b> - Awarded for a perfect 4.0 GPA</p></li>
                      <li><p><b>Deans List(x3)</b> - Awarded for a 3.5+ GPA</p></li>
                      <li><p><b>Upsilon Pi Epsilon Honor Society</b> - Invite only honor society for computer science majors</p></li>
                  </ul>
                  <br/>
                  <h2 style={{marginBottom: '0px'}}><b>Involvement:</b> </h2>
                  <ul>
                      <li><p>Association for Computing Machinery(President Emeritus)</p></li>
                      <li><p>Men's Club Volleyball</p></li>
                      <li><p>Men's Club Rugby</p></li>
                      <li><p>'Noles Everywhere</p></li>
                      <li><p>Undergraduate Research Opportunity Program</p></li>
                      <li><p>Cubing club</p></li>
                      <li><p>Chess club</p></li>
                  </ul>
                  <br/>
                  <br/>
                  <h1 style={{ textDecoration: 'underline', marginBottom: '0px'}}>
                  <img src = '../../images/wy-logo.png' alt = "WY" className='icon'/>
                    HIGH SCHOOL - Whitney Young</h1>
                  <div className="dates-ed">
                      <h1>August 2018 - May 2022</h1>
                  </div>
                  <p style={{marginBottom: '0px'}}><b>GPA:</b> 4.8</p>
                  <br/>
                  <h2 style={{marginBottom: '0px'}}><b>Involvement:</b> </h2>
                  <ul>
                      <li><p>4 year varsity starter on Football and Volleyball</p></li>
                      <li><p>Economics Club</p></li>
                      <li><p>Japanese National Honor Society</p></li>
                      <li><p>Japan Bowl</p></li>
                      <li><p>Fitness Club</p></li>
                  </ul>
                  <br/>
                  <h2 style={{marginBottom: '0px'}}><b>Awards and Honors:</b> </h2>
                  <ul>
                      <li><p>Rapunzl National HS Investing Scholarship - 3rd Place</p></li>
                      <li><p>All-Conference for Football</p></li>
                      <li><p>All-State Academic for Football</p></li>
                      <li><p>City Champions in Volleyball</p></li>
                  </ul>
              </div>
              <div className='right-align'>
                  <img src='../../images/img-1986.jpg' alt="Me"/>
                  <p className='caption'>First day of freshman year</p>
                  <img src='../../images/img-7716.jpeg' alt="Me"/>
                  <p className='caption'>#1 Noles Fan</p>
                  <img src='../../images/img-2226.jpg' alt="Me"/>
                  <p className='caption'>Graduated from Whitney Young</p>
              </div>
            </div>
        </div>
    )
}