import React from 'react'
import '../../App.css'
import './Pages.css'

export default function RelevantExperience() {
    return (
        <div className='page-container'>
            <h1>RELEVANT EXPERIENCE</h1>
            <div className='column-layout'>
            <div className="left-align">
            <h1>Committee Member - Student Council for Undergraduate Research and Creativity</h1>
                <div className="dates">
                    <h1>August 2024 - Present</h1>
                </div>
                <ul>
                    <li><p>Advocate for increased accessibility and approachability 
                        of research opportunities for undergraduate students at FSU</p></li>
                    <li><p>Serve on the programming committee to design, plan, and 
                        staff events that promote undergraduate research involvement</p></li>
                </ul>
                <br/>
                <h1>Department Editor - Association for Computing Machinery</h1>
                <div className="dates">
                    <h1>July 2024 - Present</h1>
                </div>
                <ul>
                    <li><p>Editor for XRDS, ACM's global magazine publication for computing students</p></li>
                    <li><p>Write the "Milestones" section of each issue</p></li>
                </ul>
                <br/>
                <h1>Lead Teaching Assistant - Florida State University</h1>
                <div className="dates">
                    <h1>June 2024 - Present</h1>
                </div>
                <ul>
                    <li><p>AServe as a primary support resource for students in a computer science scholarship program aimed at underprivileged students</p></li>
                    <li><p>Conduct weekly office hours, provide individual support, host workshops, and assist with college life challenges</p></li>
                    <li><p>Coordinate and lead the efforts of other teaching assistants to ensure a cohesive and effective support system</p></li>
                </ul>
                <br/>
                <h1>Research Assistant - Stagg Lab FSU</h1>
                <div className="dates">
                    <h1>September 2023 - Present</h1>
                </div>
                <ul>
                    <li><p>Utilize Python and real-time image processing to develop Magellon, the next generation of software for automated data collection 
                        and processing for cryo-electron microscopes</p></li>
                    <li><p>Updated hole finding code to increase efficiency and accuracy when identifying holes in samples</p></li>
                    <li><p>Implemented adapter code to transfer xml metadata from previous platforms for use in Magellon</p></li>
                    <li><p>Collaborate on an extensive codebase shared between 3 university research teams</p></li>
                </ul>
                <br/>
                <h1>Director of Outreach - 'Noles Everywhere</h1>
                <div className="dates">
                    <h1>April 2023 - Present</h1>
                </div>
                <ul>
                    <li><p>‘Noles Everywhere is a program at FSU that aims to create a welcoming environment for out-of-state students</p></li>
                    <li><p>As an ambassador, I help plan and work our monthly events. Interacting with students in the process, serving as a point 
                        of contact for students to reach out to with any questions.</p></li>
                    <li><p>As director of outreach, I coordinate how all our ambassadors personally reach out to students on a monthly basis</p></li>
                </ul>
                <br/>
                <h1>Software Development Intern - Wintrust Financial</h1>
                <div className="dates">
                    <h1>May 2024 - August 2024</h1>
                </div>
                <ul>
                    <li><p>Develop software automations for different departments of Wintrust Financial, one of Chicagoland's
                        largest banks($60+ Billion in assets), as a part of the Research & Development team</p></li>
                    <li><p>Participate in sprints and daily scrum meetings</p></li>
                    <li><p>Utilized UiPath to develop an automation for the Mortgage department that automated a daily loan report submission, 
                        saving the company over 250 man-hours a year</p></li>
                </ul>
                <br/>
                <h1>President - Association for Computing Machinery @ FSU</h1>
                <div className="dates">
                    <h1>April 2023 - April 2024</h1>
                </div>
                <ul>
                    <li><p>Run the largest computer science organization at FSU(420+ members). Plan events such as the semesterly programming contest(300+ contestants), 
                        recruiting events from companies such as Google, JPMorgan, L3Harris, and more, student presentations, general body meetings, social events, skill 
                        building workshops and more</p></li>
                    <li><p>Led several skill building workshops such as technical interview workshops and a coding with AI workshop</p></li>
                    <li><p>Increased member count by over 50%(140+ new members) and average meeting attendance by over 225%</p></li>
                    <li><p>Previously served as the marketing chair</p></li>
                </ul>
                <br/>
                <h1>Data Analyst Intern - Access2Insight</h1>
                <div className="dates">
                    <h1>May 2023 - August 2023</h1>
                </div>
                <ul>
                    <li><p>Utilize SQL and Excel to import, clean, and transfer data from extensive relational databases with nearly 100 million registered users and 
                        billions of records</p></li>
                    <li><p>Updated and enhanced logic to increase the accuracy and efficiency of brand classification code used for sales lift studies, lifetime value 
                        calculations, and marketing effectiveness</p></li>
                    <li><p>Analyzed and restructured data to calculate sales lift that demonstrated the effectiveness of marketing efforts</p></li>
                </ul>
                <br/>
                <h1>Languages</h1>
                <ul>
                    <li><p>C++(Proficient)</p></li>
                    <li><p>Python(Proficient)</p></li>
                    <li><p>SQL(Proficient)</p></li>
                    <li><p>Java(Intermediate)</p></li>
                    <li><p>JSX(Intermediate)</p></li>
                    <li><p>CSS(Intermediate)</p></li>
                </ul>
                <br/>
                <h1>Skills</h1>
                <ul>
                    <li><p>Data structures and algorithms</p></li>
                    <li><p>UiPath</p></li>
                    <li><p>Unix</p></li>
                    <li><p>SwaggerUI</p></li>
                    <li><p>Git</p></li>
                    <li><p>React</p></li>
                    <li><p>Microsoft Office</p></li>
                </ul>
                </div>
                <div className='right-align'>
                  <img src='../../images/img-10.jpg' alt="Me"/>
                  <p className='caption'>Feautured on Wintrust Social Media</p>
                  <img src='../../images/img-8.jpg' alt="Me"/>
                  <p className='caption'>Access2Insight Internship</p>
                  <img src='../../images/img-7737.jpg' alt="Me"/>
                  <p className='caption'>Me presenting my research at the 2024 Undergraduate Research Symposium</p>
                  <img src='../../images/img-2.jpg' alt="Me"/>
                  <p className='caption'>'Noles Everywhere Headshot</p>
              </div>
                </div>
            </div>
    )
}