import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  console.log(button);

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className = "navbar">
        <div className="navbar-container">
            <Link to="/" className="navbar-logo" onClick = {() => setClick(false)}>
              &lt;FRANK SCHOTANUS/&gt;
            </Link>
            <div className="menu-icon" onClick = {() => setClick(!click)}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className = {click ? 'nav-menu active' : 'nav-menu'}>
              <li className="nav-item">
                <Link to ="/" className="nav-links" onClick = {() => setClick(false)}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to ="/Aboutme" className="nav-links" onClick = {() => setClick(false)}>
                  About me
                </Link>
              </li>
              <li className="nav-item">
                <Link to ="/Education" className="nav-links" onClick = {() => setClick(false)}>
                  Education
                </Link>
              </li>
              <li className="nav-item">
                <Link to ="/Projects" className="nav-links" onClick = {() => setClick(false)}>
                  Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link to ="/RelevantExperience" className="nav-links" onClick = {() => setClick(false)}>
                  Relevant Experience
                </Link>
              </li>
              <li className="nav-item">
                <Link to ="/IrrelevantExperience" className="nav-links" onClick = {() => setClick(false)}>
                  Irrelevant Experience
                </Link>
              </li>
            </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar;
