import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import IrrelevantExperience from './components/pages/IrrelevantExperience';
import RelevantExperience from './components/pages/RelevantExperience';
import AboutMe from './components/pages/AboutMe';
import Projects from './components/pages/Projects';
import Footer from './components/Footer';
import Education from './components/pages/Education';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/IrrelevantExperience' exact element={<IrrelevantExperience />} />
          <Route path='/RelevantExperience' exact element={<RelevantExperience />} />
          <Route path='/AboutMe' exact element={<AboutMe />} />
          <Route path='/Projects' exact element={<Projects />} />
          <Route path='/Education' exact element={<Education />} />
        </Routes>
      </Router>
      <Footer/>
    </>
  );
}

export default App;
